<template>
  <div class="tab-content">
    <div class="pagenamPT">
      <h3 class="pagename2">{{ $t('front.common.dailyUser') }}</h3>
      <date-filter-Pt :startDate="startDate"
                      :endDate="endDate"
                      @update="updateDate"
                      @search="loadList" />
    </div>
    <article class="content-wrap">
      <div class="strTablescr">
        <div class="strTablePC">
          <table class="statTable">
            <thead>
              <tr>
                <th>{{ $t('front.common.partnerID') }}</th>
                <th>{{ $t('front.common.userID') }}</th>
                <th>{{ $t('front.common.nickName') }}</th>
                <th class="text-right">{{ $t('front.stributor.m22') }}</th>
                <th class="text-right">{{ $t('front.stributor.m23') }}</th>
                <th>-</th>
              </tr>
            </thead>
            <tbody>
              <template v-if="dailyUserList && dailyUserList.length > 0">
                <tr v-for="item in dailyUserList" :key="item.memId">
                  <td>{{ item.upperId }}</td>
                  <td>{{ item.memId }}</td>
                  <td>{{ item.memNick }}</td>
                  <td class="text-right">{{ thousand(item.dBetAmt || 0) }}</td>
                  <td class="text-right">{{ thousand(item.dWinAmt || 0) }}</td>
                  <td>-</td>
                </tr>
              </template>
              <template v-else>
                <tr><td colspan="6">{{ $t('front.common.notFoundList') }}</td></tr>
              </template>
            </tbody>
            <tfoot v-if="dailyUserSum">
              <tr>
                <td colspan="3">합계</td>
                <td class="text-right">{{ thousand(dailyUserSum.dBetAmt || 0) }}</td>
                <td class="text-right">{{ thousand(dailyUserSum.dWinAmt || 0) }}</td>
                <td>-</td>
              </tr>
            </tfoot>
          </table>
        </div>
        <div class="strTableM">
          <div class="strTablePTM">
            <ul v-if="dailyUserSum" class="total">
              <li>합계</li>
              <li>{{ thousand(dailyUserSum.dBetAmt || 0) }}</li>
              <li>{{ thousand(dailyUserSum.dWinAmt || 0) }}</li>
              <li>-</li>
            </ul>
            <template v-if="dailyUserList && dailyUserList.length > 0">
              <template v-for="item in dailyUserList" :key="item.memId">
                <ul>
                  <li>
                    <em>파트너 ID</em>
                    <div>{{ item.upperId }}</div>
                  </li>
                  <li>
                    <em>사용자 ID</em>
                    <div>{{ item.memId }}</div>
                  </li>
                  <li>
                    <em>닉네임</em>
                    <div>{{ item.memNick }}</div>
                  </li>
                  <li>
                    <em>베팅금</em>
                    <div>{{ thousand(item.dBetAmt || 0) }}</div>
                  </li>
                  <li>
                    <em>당첨금</em>
                    <div>{{ thousand(item.dWinAmt || 0) }}</div>
                  </li>
                  <li>
                    <em>-</em>
                    <div>-</div>
                  </li>
                </ul>
              </template>
            </template>
            <template v-else>
              <ul>
                <li>내역이 없습니다.</li>
              </ul>
            </template>
          </div>
        </div>
      </div>
    </article>
  </div>
</template>
<script>
import DateFilterPt from '@/components/ui/DateFilterPt'
import { getDateStr, thousand } from '@/libs/utils'
import { addDays } from 'date-fns'
import { mapState } from 'vuex'
import { getGameStaticsDailyUsersV2 } from '@/api/v2/statics'
export default {
  name: 'DateUsers',
  components: { DateFilterPt },
  data () {
    return {
      dailyUserList: null,
      dailyUserSum: null,
      startDate: getDateStr(addDays(new Date(), 0), 'yyyy-MM-dd 00:00:00'),
      endDate: getDateStr(addDays(new Date(), 0), 'yyyy-MM-dd 23:59:59')
    }
  },
  computed: {
    ...mapState([
      'userData',
      'gameCount',
      'commonCodeByOrder',
      'commonCodeByCode'
    ])
  },
  created () {
    this.loadList()
  },
  methods: {
    thousand,
    updateDate (value) {
      this.startDate = value.startDate
      this.endDate = value.endDate
    },
    loadList () {
      this.emitter.emit('Loading', true)
      this.dailyUserList = null
      this.dailyUserSum = null

      const params = {
        memId: this.userData.memId,
        startDate: this.startDate,
        endDate: this.endDate
      }
      getGameStaticsDailyUsersV2(params).then(res => {
        console.log('getGameStaticsDailyUsersV2 : ', params, res.data)
        const result = res.data
        if (result.resultCode === '0') {
          this.dailyUserList = result.data.dailyUserList
          this.dailyUserSum = result.data.dailyUserSum
          this.emitter.emit('Loading', false)
        } else {
          this.emitter.emit('Loading', false)
          alert('다시 시도해주세요. ', result.resultMessage)
        }
      })
    }
  }
}
</script>
<style src="@/styles/pt.css"></style>
<style scoped>
@media (max-width: 1000px) {
  .pagenamPT {flex-direction: column;align-items: flex-start;}
}
</style>
